import React from "react";
import { STEEZYLogo, CloseCircle, StarRating4pt7 } from "app/components/Icon";
import { Routes } from "constants/routes";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";

export function LogoNav() {
  const history = useHistory();

  return (
    <>
      <CloseCircle
        className={styles.closeCircle}
        onClick={() => history.push(Routes.dashboard)}
      />

      <div className={styles.checkoutHeaderContainer}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <STEEZYLogo className={styles.STEEZYLogo} />
            <div style={{ display: "flex", gap: "16px" }}>
              <StarRating4pt7 className={styles.stars} />
              <p className={styles.ratings}>4.7 • 7K+ ratings</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
